<template>
  <div>
    <b-row>
      <b-col class="text-center mt-1" cols="12">
        <b-button variant="primary" @click="getOptionWords">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Select word from pool</span>
        </b-button>
        <b-button class="ml-2" variant="warning" @click="openCreateModal">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Create new word</span>
        </b-button>
        <b-button v-if="allowCustomWord && customWordDefault" class="ml-2" variant="danger" @click="addCustomWord">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Create custom word</span>
        </b-button>
      </b-col>
    </b-row>
    <vue-select class="vue-select1 mt-2" name="select1"
      v-if="word_options && is_select_word"
      :options="word_options"
      v-model="selected_word"
      label="label" :reduce="label => label.value"
      @input="selectWord"
      :searchable="true"
      @search="updateWordOptions"
      placeholder="Find & select"
      style="background-color: white"
    />
    <word-create-modal
      @addWord="addWord"
      ref="modalWord"
    />
  </div>
</template>

<script>
import VueSelect from 'vue-select'
import WordCard from "../../../words/views/_components/WordCard.vue";
import WordCreateModal from "../../../words/views/_components/WordCreateModal";
import _ from 'lodash'
import service from '../../../words/service'
// import milestoneService from '../../../milestone/service'
export default {
  components: {
    VueSelect,
    WordCard,
    WordCreateModal,
  },
  props:{
    allowCustomWord: {type: Boolean, default: false},
    customWordDefault: {type: Object, default: null},
  },
  data() {
    return {
      option_words: [],
      word_options: [],
      is_select_word: false,
      selected_word: null,
      filter: null
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.modalWord.openCreateModal()
    },
    addWord(word) {
      this.$emit('wordAdded', word)
    },
    async getOptionWords() {
      
      let wordList = await service.getList({
        query: this.filter ? JSON.stringify(this.filter) : {},
        limit: 100
      });
      this.option_words = wordList?.data?.data?.list || [];
      this.word_options = [];
      this.option_words.forEach(word => {
        this.word_options.push({
          label: word.title,
          value: word._id,
          id: word.title + "-" + word._id + Math.random()
        })
      })
      this.is_select_word = true;
    },
    selectWord() {
      let word = this.option_words.find(item => item._id === this.selected_word);
      if (!word) {
        console.log('selected_word_notfound',this.selected_word);
        return;
      }
      this.$emit('wordSelected', word)
      this.selected_word = null
    },
    async updateWordOptions(search_key, loading){
      if (search_key !== "") {
        loading(true);
        this.filter = {title: {$regex: search_key, "$options": 'i'}}
        _.debounce(()=>{ this.getOptionWords(); loading(false);}, 1000)();
      }
    },
    addCustomWord(){
      this.addWord(this.customWordDefault);
    }
  },
};
</script>
